import type {
    LabelProps,
    ShorthandValue,
    StyleRule,
} from '@archipro-design/aria';
import { useStyles } from '@archipro-design/aria';
import { ToastMessage } from '@archipro-design/aria';

import { useMemoizedFn } from 'ahooks';
import React, { useCallback, useMemo, useRef, useState } from 'react';

export interface ToastOptions {
    type?: 'error' | 'success' | 'notification';
    autoHideDelay?: number;
    clickHereLink?: ShorthandValue<LabelProps>;
    styleRule?: StyleRule;
}

interface ToasterContextType {
    toast: (message: string, options?: ToastOptions) => void;
    closeToast: () => void;
}

const ToasterContext = React.createContext<ToasterContextType | undefined>(
    undefined
);

export const useToaster = (
    initialOptions: Omit<ToastOptions, 'clickHereLink' | 'type'> = {}
) => {
    const context = React.useContext(ToasterContext);

    if (!context) {
        throw new Error('Cannot be used outside ToasterContext');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedOptions = useMemo(() => initialOptions, []);
    const memoizedToast = useMemoizedFn(
        (message: string, options?: ToastOptions) =>
            context.toast(message, { ...memoizedOptions, ...options })
    );

    const enquiryToastClickHere = useMemoizedFn((generatedMember = false) => ({
        as: 'a', // Todo: change to Link after we have the Remix version
        href: generatedMember
            ? `/member/settings/security?changePassword=1`
            : '/member/inbox',
        children: 'Click here',
        onClick: context.closeToast,
    }));

    return {
        toast: memoizedToast,
        closeToast: context.closeToast,
        enquiryToastClickHere,
    };
};

interface Toast extends ToastOptions {
    message: string;
    // This is an incremetal ID to maintain the order of the toasts
    index: number;
}

export const ToasterProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const [messages, setMessages] = useState<Toast[]>([]);
    const { css } = useStyles();
    const counter = useRef(0);

    const toast = useCallback(
        (newMessage: string, options: ToastOptions = {}) => {
            // Shouldn't ever reach this.. but.. sanity
            if (counter.current === Number.MAX_SAFE_INTEGER) {
                counter.current = 0;
            }

            setMessages((messages) => {
                const added = messages.concat({
                    message: newMessage,
                    index: counter.current++,
                    ...options,
                });
                return added;
            });
        },
        []
    );

    const closeToast = useMemoizedFn((index?: number) => {
        setMessages((messages) => {
            const clone = messages.slice();
            // No index, just pop the latest
            if (typeof index === 'number') {
                const toDeleteMsg = messages.find((m) => m.index === index);
                // Shouldn't fail.. but.. sanity
                if (toDeleteMsg) {
                    clone.splice(toDeleteMsg.index, 1);
                }
            } else {
                clone.pop();
            }
            return clone;
        });
    });

    const toasterContext = useMemo(
        () => ({ toast, closeToast }),
        [toast, closeToast]
    );

    return (
        <ToasterContext.Provider value={toasterContext}>
            {children}
            {messages.map(({ message, index, styleRule, ...rest }) => {
                return (
                    <ToastMessage
                        key={index}
                        message={message}
                        onClose={() => closeToast(index)}
                        className={styleRule ? css(styleRule) : undefined}
                        style={{ bottom: `${index * 10}px` }}
                        {...rest}
                    />
                );
            })}
        </ToasterContext.Provider>
    );
};
