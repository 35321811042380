import type { StyleRule } from '@archipro-design/aria';
import { SubheaderClassName } from '@archipro-design/aria';
import { captionClassName } from '@archipro-design/aria';
import { dialogSlotClassNames } from '@archipro-design/aria';
import { buttonClassName, pxToRem } from '@archipro-design/aria';
import { loginModalSlotClassNames } from '@archipro-design/aria';
import { svgIconClassName } from '@archipro-design/icons';
import { getDvhStyles } from '~/modules/design-board/util';

export const LoginModal: StyleRule = ({ theme }) => ({
    ...getDvhStyles('height'),
    [theme.screen.tablet]: {
        height: 'auto',
    },

    [`& .${loginModalSlotClassNames.header}`]: {
        [theme.screen.tablet]: {
            marginBottom: pxToRem(16),
        },
    },
});

export const OverlayClass: StyleRule = ({ theme }) => ({
    ...getDvhStyles('height'),
    border: `1px solid ${theme.siteVariables.colors.white['100A']}`,
});

export const LoginMessage: StyleRule = () => ({
    maxWidth: pxToRem(330),
    margin: 'auto',
    marginTop: pxToRem(-16),
});

export const LoginSubtitle: StyleRule = () => ({
    maxWidth: pxToRem(518),
    margin: 'auto',
    marginTop: pxToRem(-28),
    marginBottom: pxToRem(66),
    textAlign: 'center',
});

export const LoginModalContent: StyleRule = ({ theme }) => ({
    [`& .${SubheaderClassName}`]: {
        textAlign: 'center',
        color: theme.siteVariables.colors.charcoal[250],
        marginBottom: pxToRem(36),
    },
});

export const CommonCaption: StyleRule = ({ theme }) => ({
    textAlign: 'center',
    color: theme.siteVariables.colors.charcoal[250],
    marginBottom: pxToRem(36),
});

export const ForgotPasswordModal: StyleRule = ({ theme }) => ({
    [`& .${loginModalSlotClassNames.header}`]: {
        [`& .${buttonClassName}`]: {
            left: pxToRem(8),
            right: 'auto !important',
            [theme.screen.laptop]: {
                left: pxToRem(18),
            },

            [`& .${svgIconClassName}`]: {
                height: pxToRem(22),
                width: pxToRem(22),
            },
        },
    },
    [`& .${dialogSlotClassNames.footer}`]: {
        margin: 0,
    },
});

export const FormLink: StyleRule = ({ theme }) => ({
    [`& .${captionClassName}`]: {
        color: theme.siteVariables.colors.charcoal[250],
    },
});
