/* eslint-disable max-lines */
import {
    Button,
    Caption,
    Flex,
    pxToRem,
    TextInput,
    useStyles,
    useTheme,
} from '@archipro-design/aria';

import { z } from 'zod';

import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useFetcher } from '@remix-run/react';

import { zodResolver } from '@hookform/resolvers/zod';
import { EMAIL_INPUT } from '~/modules/validation/util/form';
import type { ForgotPasswordAction } from '~/routes/remix-api.user.forgot-password';
import React, { useEffect } from 'react';
import * as S from './ForgotPasswordForm.style';
import { useLatest } from 'ahooks';
import { useToaster } from '~/modules/root/context/ToasterContext';
import type { LoginFlow } from '../../hook/use-login-flow';

const forgotPasswordValidator = z.object({
    email: EMAIL_INPUT,
});

type FormShape = z.infer<typeof forgotPasswordValidator>;

interface ForgotPasswordFormProps {
    flow: Extract<LoginFlow, 'forgot-password' | 'forgot-password-completed'>;
    onBack?: (e: React.SyntheticEvent<HTMLElement, Event>) => void;
    onSuccess?: () => void;
    onFailure?: () => void;
}

const ForgotPasswordForm = (props: ForgotPasswordFormProps) => {
    const { onSuccess, onFailure, onBack, flow } = props;

    const { css } = useStyles();
    const theme = useTheme();
    const { toast } = useToaster();

    const forgotFetcher = useFetcher<ForgotPasswordAction>();

    const { control, handleSubmit, getValues } = useForm<FormShape>({
        resolver: zodResolver(forgotPasswordValidator),
        mode: 'onBlur',
    });

    const onSubmit: SubmitHandler<FieldValues> = (data) => {
        if (forgotFetcher.state !== 'idle') return;
        forgotFetcher.submit(data, {
            method: 'post',
            action: `/remix-api/user/forgot-password`,
        });
    };

    const memoisedSuccess = useLatest(onSuccess);
    const memoisedFailure = useLatest(onFailure);

    useEffect(() => {
        const success = forgotFetcher.data && 'success' in forgotFetcher.data;
        const error = forgotFetcher.data && 'error' in forgotFetcher.data;
        if (success && forgotFetcher.state === 'idle') {
            toast('A new reset link has been sent to your email');
            memoisedSuccess.current?.();
        }
        if (error && forgotFetcher.state === 'idle') {
            memoisedFailure.current?.();
        }
    }, [forgotFetcher, memoisedSuccess, memoisedFailure, toast]);

    return (
        <Flex column>
            <Caption
                variant="03-alt"
                design={{ marginBottom: pxToRem(16) }}
                variables={{
                    captionColor: theme.siteVariables.colors.charcoal['250'],
                }}
            >
                {flow === 'forgot-password-completed'
                    ? `We sent an email to ${getValues(
                          'email'
                      )}. If this email is connected to an ArchiPro account, you’ll be able to reset your password.`
                    : `Enter the email address associated with your account and we’ll email you a link to reset your password.
            `}
            </Caption>

            {flow === 'forgot-password' && (
                <forgotFetcher.Form
                    id="forgotPasswordForm"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Controller<FormShape>
                        control={control}
                        name={'email'}
                        render={({ field, fieldState: { error } }) => (
                            <TextInput
                                state={error?.message ? 'error' : 'default'}
                                message={error?.message}
                                size="md"
                                input={{
                                    ...field,
                                    placeholder: 'Enter email address.',
                                    label: 'Email address',
                                    required: true,
                                }}
                            />
                        )}
                    />

                    <Button
                        design={{
                            marginTop: pxToRem(44),
                        }}
                        key="forgot-password-button"
                        type="submit"
                        fluid
                        size={18}
                        variables={{ buttonPadding: 16 }}
                        disabled={forgotFetcher.state !== 'idle'}
                    >
                        SEND RESET LINK
                    </Button>
                </forgotFetcher.Form>
            )}

            {flow === 'forgot-password-completed' && (
                <Flex column gap="space-16">
                    <Button
                        key="back-to-login-button"
                        fluid
                        size={18}
                        variables={{ buttonPadding: 16 }}
                        onClick={(e) => onBack?.(e)}
                    >
                        BACK TO LOGIN
                    </Button>
                    <span className={css(S.FormLink)}>
                        Didn’t get the email? Try
                        <Button
                            selected={true}
                            transparent={true}
                            variables={{ buttonPadding: 0 }}
                            onClick={() => {
                                onSubmit(getValues());
                            }}
                        >
                            resend email
                        </Button>
                        again.
                    </span>
                </Flex>
            )}
        </Flex>
    );
};

export default ForgotPasswordForm;
