import {
    FacebookColor,
    GoogleColor,
    LinkedinColor,
} from '@archipro-design/icons';

import {
    SOCIAL_PROVIDERS,
    type SocialLoginOptions,
    type SocialLoginProvider,
} from '../type';
import type { AuthSource } from '~/modules/user/type';
import { useLocation, useSearchParams } from '@remix-run/react';

const ICONS: Record<SocialLoginProvider, JSX.Element> = {
    Facebook: <FacebookColor />,
    Google: <GoogleColor />,
    LinkedIn: <LinkedinColor />,
};

interface UseSocialLoginsOptions {
    loginOptions?: SocialLoginProvider[];
    authSource: AuthSource;
    redirect?: string;
    skipPostSignUp: boolean;
    isInstagramOrFacebookApp: boolean;
}

export const useSocialLogins = ({
    loginOptions,
    authSource,
    redirect,
    skipPostSignUp,
    isInstagramOrFacebookApp,
}: UseSocialLoginsOptions): SocialLoginOptions[] => {
    let socials = loginOptions ?? SOCIAL_PROVIDERS;

    if (isInstagramOrFacebookApp) {
        socials = socials.filter((provider) => provider !== 'Google');
    }

    const [searchParams] = useSearchParams();
    const urlRedirect = searchParams.get('redirect');
    const { pathname, search } = useLocation();

    const authRedirect = redirect ?? urlRedirect ?? `${pathname}${search}`;

    const socialLogins = socials.map((loginOption) => {
        const searchParams = new URLSearchParams();
        searchParams.set('provider', loginOption);
        searchParams.set('auth-source', authSource);
        if (authRedirect !== '/') {
            searchParams.set('redirect', encodeURI(authRedirect));
        }
        if (skipPostSignUp) {
            searchParams.set('skip-post-sign-up', '1');
        }

        return {
            KEY: loginOption.toUpperCase(),
            TITLE: loginOption,
            URL: `social?${searchParams.toString()}`,
            TRACKER_ID: loginOption.toLowerCase(),
            ICON: ICONS[loginOption],
        };
    });

    return socialLogins;
};
