import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import type { AuthProvider, AuthSource, AuthType } from '../../user/type';
import LoginSignUpModal from '../../user/component/login-signup-modal/LoginSignUpModal';
import { useLocation, useNavigation } from '@remix-run/react';
import { useUser } from '@modules/user/hook/use-user';
import { isCleanRedirect } from '~/modules/root';
import { trackAuthEvent } from '../../user/util/track-auth-event';
import { useTracker } from '@archipro-website/tracker';
import { AUTH_EVENTS } from '~/modules/root/hook/use-auth-event';
import { parseURL } from '@archipro-website/top-navigation';

interface AuthState {
    authSource: AuthSource;
    open: boolean;
    redirect?: string;
    allowExternalUrls?: boolean;
    skipPostSignUp?: boolean;
    disableCloseButton?: boolean;
    subtitle?: string;
}

interface AuthRedirectState extends Omit<AuthState, 'open'> {
    provider: AuthProvider;
    authType: AuthType;
}

export const AuthContext = React.createContext<
    | {
          showAuthModal: (props: Omit<AuthState, 'open'>) => void;
          hideAuthModal: () => void;
          authRedirect: (props: AuthRedirectState) => void;
      }
    | undefined
>(undefined);

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
    const [state, setState] = useState<AuthState>({
        authSource: 'topNav',
        open: false,
    });

    const [redirectState, setRedirectState] =
        useState<AuthRedirectState | null>(null);

    const location = useLocation();
    const user = useUser();
    const navigation = useNavigation();
    const tracker = useTracker();

    const showModal = useCallback((props: Omit<AuthState, 'open'>) => {
        setState({ ...props, open: true });
    }, []);

    const hideModal = useCallback(() => {
        setState({ authSource: 'topNav', open: false });
    }, []);

    const redirect = useCallback((props: AuthRedirectState) => {
        setRedirectState(props);
    }, []);

    useEffect(() => {
        if (
            !redirectState ||
            user.__typename !== 'Me' ||
            navigation.state == 'loading'
        )
            return;
        const guestID = user.TrackedGuest?.ID ?? 0;
        const { pathname, search } = location;
        const {
            redirect,
            allowExternalUrls,
            authSource,
            provider,
            authType,
            skipPostSignUp,
        } = redirectState;

        let newURL =
            redirect && isCleanRedirect(redirect, allowExternalUrls)
                ? decodeURI(redirect)
                : `${pathname}${search}`;
        const originSearchParams = new URLSearchParams(search);
        const encodedRedirect = originSearchParams.get('encodedRedirect');
        if (encodedRedirect) {
            newURL = `/linkshare?encoded=${encodedRedirect}`;
        } else {
            if (!skipPostSignUp) {
                const searchParams = new URLSearchParams();
                if (newURL !== '/') {
                    searchParams.set('redirect', encodeURI(newURL));
                }
                const search = searchParams.toString();
                newURL = `/post-sign-up${search ? `?${search}` : ''}`;
            }
        }

        const redirectURL = async () => {
            await trackAuthEvent({
                tracker,
                event: AUTH_EVENTS[authType],
                provider,
                authSource,
                guestID,
            });

            const { basePath: originBasePath } = parseURL(window.location.href);
            const { basePath: currentBasePath } = parseURL(newURL);
            if (authSource === 'enquiry' && newURL.includes('enquire=1')) {
                // remove the enquire=1 to avoid enquiry form show up again
                newURL = newURL.replace('enquire=1', 'enquire=0');
                // wait for the enquiry toast to display
                await new Promise((resolve) => setTimeout(resolve, 3000));
            }

            if (originBasePath === currentBasePath) {
                window.location.reload();
            } else {
                // workaround for fixing firefox redirect aborting issue
                setTimeout(() => {
                    window.location.href = newURL;
                }, 500);
            }
        };

        redirectURL();

        setRedirectState(null);
    }, [location, setState, tracker, user, navigation.state, redirectState]);

    return (
        <AuthContext.Provider
            value={{
                showAuthModal: showModal,
                hideAuthModal: hideModal,
                authRedirect: redirect,
            }}
        >
            {children}
            <LoginSignUpModal
                login={{
                    onCancel: hideModal,
                    onConfirm: hideModal,
                    onSuccess: hideModal,
                    onAuthSuccess: hideModal,
                    flow: null,
                    ...state,
                }}
                forgotPassword={{
                    open: state.open,
                    onCancel: hideModal,
                }}
            />
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = React.useContext(AuthContext);

    if (!context) {
        throw new Error('Cannot be used outside AuthContext');
    }
    return context;
};
