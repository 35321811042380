import type { Tracker, TrackingEvent } from '@archipro-website/tracker';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useUser } from '~/modules/user';
import type { AuthType } from '~/modules/user/type';
import { AUTH_PROVIDERS, AUTH_SOURCES, AUTH_TYPES } from '~/modules/user/type';
import { trackAuthEvent } from '~/modules/user/util/track-auth-event';

export const AUTH_EVENTS: Record<
    AuthType,
    Extract<TrackingEvent, 'new_account_registered' | 'user_login'>
> = {
    'sign-up': 'new_account_registered',
    'sign-in': 'user_login',
};

export const AUTH_TYPE_PARAM_KEY = 'auth-type';

export const useAuthEvent = (tracker?: Tracker) => {
    const user = useUser();
    const [searchParams, setSearchParams] = useSearchParams();

    const isMember = user.__typename === 'Me';
    const guestID = user.__typename === 'Me' ? user.TrackedGuest?.ID : 0;

    useEffect(() => {
        /**
         * get the provider, authSource and authType from the searchParams
         * if user sign up or sign in, we will add this before redirecting
         * social auth check: app/src/Component/Page/SocialAuthController.php
         * email & password auth check: LoginSignUpForm
         */
        const providerParams = searchParams.get('provider');
        const provider = AUTH_PROVIDERS.find((p) => p === providerParams);

        const authTypeParam = searchParams.get('auth-type');
        const authType = AUTH_TYPES.find((t) => t === authTypeParam);
        const event = authType ? AUTH_EVENTS[authType] : undefined;

        const authSourceParam = searchParams.get('auth-source');
        const authSource =
            AUTH_SOURCES.find((source) => source === authSourceParam) ??
            AUTH_SOURCES[0];

        if (!isMember || !provider || !tracker || !event) return;
        const logEvent = async () => {
            await trackAuthEvent({
                tracker,
                event,
                provider,
                authSource,
                guestID,
            });

            searchParams.delete('provider');
            searchParams.delete('auth-type');
            searchParams.delete('auth-source');
            // force reload to reset tracker and google tags
            const search = searchParams.toString();
            return `${window.location.pathname}${search ? `?${search}` : ''}`;
        };
        logEvent().then((url) => {
            // in Firefox it works abnormal so need to force to delay the redirect
            // wait until tasks in microtask queue of this round finished
            setTimeout(() => (window.location.href = url));
        });
    }, [isMember, tracker, searchParams, setSearchParams, guestID]);
};
